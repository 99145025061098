import React, { useEffect, useState } from 'react';
import { SizeUnitsApi } from 'store/slices';
import { ModalWindow, SizeUnitCard, SizeUnitEditor } from 'components';
import { PlusIcon } from 'resources/icons';
import css from './index.module.scss';

type TProps = {
  productId: number,
};

export const ProductSizeUnits = ({ productId }: TProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  
  const { data: sizeUnits } = SizeUnitsApi.useGetSizeUnitsByProductQuery(productId);

  const showModal = () => setIsModalVisible(true);
  const hiddenModal = () => setIsModalVisible(false); 

  return (
    <>
      <div className={css.wrap}>
        <h2>Размеры</h2>
        <div className={css.items}>
          {
            !!sizeUnits?.data && sizeUnits.data.map(
              sizeUnit => <SizeUnitCard sizeUnit={sizeUnit} productId={productId} key={sizeUnit.id} />
            )
          }
          <button type='button' className={css.addBtn} onClick={showModal}>
            <PlusIcon className={css.icon} />
          </button>
        </div>
      </div>

      <ModalWindow
        title='Создание размерной единицы'
        isVisible={isModalVisible}
        hiddenAction={hiddenModal}
      >
        <SizeUnitEditor productId={productId} additionalHandler={hiddenModal} />
      </ModalWindow>
    </>
  );
};
