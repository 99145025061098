import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { apiUrl } from "resources/api";
import { prepareHeadersBase } from "store/baseStateManager";

export const RefreshApi = createApi({
  reducerPath: 'refreshApi',
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl(),
    prepareHeaders: prepareHeadersBase,
  }),
  endpoints: (builder) => ({
    updateAmountProducts: builder.mutation<void, void>({
      query: () => ({
        url: 'product-groups/updateAmountProducts',
        method: 'PUT',
      })
    }),

    updateMinPrices: builder.mutation<void, void>({
      query: () => ({
        url: 'products/updateMinPrices',
        method: 'PUT',
      })
    }),
  }),
});