import React, { useState } from 'react';
import { Input } from 'components';
import { MoldedSizeGroupsApi } from 'store/slices';
import classNames from 'classnames';
import { TMoldedSizeGroup } from 'store/slices/moldedSizeGroupsApi/entities';
import { InfoIcon } from 'resources/icons';
import css from './index.module.scss';

type TProps = {
  productId: number,
  sizeGroup?: TMoldedSizeGroup,
  additionalHandler?: () => void,
};

type TStateType = Omit<TMoldedSizeGroup['attributes'], 'size_units'>;

export const MoldedSizeGroupEditor = ({ productId, sizeGroup, additionalHandler }: TProps) => {
  const [ isError, setIsError ] = useState(false);
  const [data, setData] = useState<TStateType>(
    sizeGroup?.attributes || { price: 0, sale: 0, startLength: 0, finishLength: 0 }
  );

  const [ createSizeGroup ] = MoldedSizeGroupsApi.useCreateGroupForProductMutation();
  const [ updateSizeGroup ] = MoldedSizeGroupsApi.useUpdateGroupMutation();

  const changeField = (field: keyof TStateType) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = +event.target.value;
    setData({ ...data, [field]: value }) 
  };

  const saveHandler = () => {
    if (data.price < data.sale) {
      setIsError(true);
      return;
    } else {
      setIsError(false);
    }

    if (sizeGroup) updateSizeGroup({ id: sizeGroup.id, price: data.price, sale: data.sale });
    else createSizeGroup({ product: productId, ...data });

    if (additionalHandler) additionalHandler();
  }

  return (
    <div className={css.wrap}>
      <div className={css.fields}>
        <Input 
          type='number' 
          label='Длина изделия от, м' 
          value={data.startLength} 
          onChange={changeField('startLength')}
          disabled={!!sizeGroup}
        />
        <Input 
          type='number' 
          label='Длина изделия до, м' 
          value={data.finishLength} 
          onChange={changeField('finishLength')}
          disabled={!!sizeGroup}
        />
      </div>
      <div className={css.fields}>
        <Input 
          type='number' 
          label='Основная цена за пог.м' 
          value={data.price} 
          onChange={changeField('price')}
        />
        <Input 
          type='number' 
          label='Цена со скидкой за пог.м' 
          value={data.sale} 
          onChange={changeField('sale')}
        />
      </div>
      {
        isError && (
          <div className={css.error}>
            <InfoIcon className={css.errorIcon} />
            <p className={css.errorText}>Основная цена должна быть больше цены со скидкой!</p>
          </div>
        )
      }
      <div className={css.buttons}>
        <button type='button' className={classNames(css.btn, css.saveBtn)} onClick={saveHandler}>Сохранить</button>
      </div>
    </div>
  );
};
