import React from 'react';
import { useRoutes } from 'react-router-dom';
import { ProtectedRoute } from './ProtectedRoute';
import { 
  AppOutlet, 
  AuthPage, 
  GalleryImagePage, 
  GalleryPage, 
  HomePage,
  OutdatedPricesPage,
  ProductGroupPage,
  ProductPage,
  SearchPage,
} from 'pages';


const AppRouter = () => {
  const routes = useRoutes([
    {
      path: '/auth',
      element: <AuthPage />
    },
    {
      path: '/',
      element: <ProtectedRoute><AppOutlet /></ProtectedRoute>,
      children: [
        {
          index: true,
          element: <HomePage />
        },
        {
          path: 'gallery',
          element: <GalleryPage />
        },
        { 
          path: 'gallery/:imageId',
          element: <GalleryImagePage />
        },
        {
          path: ':productGroupKey',
          element: <ProductGroupPage />
        },
        {
          path: ':productGroupKey/:productId',
          element: <ProductPage />
        },
        {
          path: 'search',
          element: <SearchPage />
        },
        {
          path: 'outdated',
          element: <OutdatedPricesPage />
        }
      ]
    },
  ]);

  return routes;
}

export default AppRouter;
