import React, { useEffect, useState, useRef } from 'react';
import css from './index.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { GalleryApi } from 'store/slices';
import { baseUrl } from 'resources/api';
import { ActionButton, DebounsedInput, GalleryImageComment, GalleryImageData, ModalWindow } from 'components';
import { TRelatedProduct_POST } from 'store/slices/galleryApi/entities';
import { GhostIcon } from 'resources/icons';

type TRelatedProductPosition = Omit<TRelatedProduct_POST, 'gallery_image' | 'product'>;

export const GalleryImagePage = () => {
  const navigate = useNavigate();
  const { imageId } = useParams();
  const imageRef = useRef<HTMLImageElement>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [newRelateProductPosition, setNewRelateProductPosition] = useState<TRelatedProductPosition>({ markOffsetLeft: 0, markOffsetTop: 0 });

  const goGallery = () => navigate('/gallery');
  const [ getUnit, { data: unit, isFetching } ] = GalleryApi.useLazyGetGalleryUnitQuery();
  const [ removeRelatedProduct ] = GalleryApi.useRemoveImageRelatedProductMutation();
  const [ searchProducts, { data: products, isFetching: isProductsLoading } ] = GalleryApi.useLazySearchProductsForRelateQuery();
  const [ setRelatedProductRequest ] = GalleryApi.useSetImageRelatedProductMutation();

  const setImageClickPosition = (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    if (!imageRef.current) return;

    const rect = imageRef.current.getBoundingClientRect();
    const onePercentWidth = rect.width / 100;
    const onePercentHeight = rect.height / 100;
    setNewRelateProductPosition({ 
      markOffsetLeft: Math.round((event.clientX - rect.left) / onePercentWidth),
      markOffsetTop: Math.round((event.clientY - rect.top) / onePercentHeight) 
    });
    setIsModalVisible(true);
  }

  const setRelatedProduct = (productId: number) => {
    if (!unit || !unit.data) return;
    setRelatedProductRequest({ ...newRelateProductPosition, gallery_image: unit.data.id, product: productId });
    setIsModalVisible(false);
  }

  useEffect(() => {
    if (imageId && !isNaN(+imageId)) getUnit(+imageId);
  }, [imageId]);

  useEffect(() => {
    if (unit && unit.data === null) goGallery();
  }, [unit]);

  return (
    <div className={css.wrap}>
      <h1><span className={css.id}>{ `id: ${imageId}` }</span>Фото работы</h1>
      { isFetching && <p className={css.loading}>Ожидайте, идет загрузка...</p> }
      {
        !!unit && !!unit.data && (
          <div className={css.content}>
            <div className={css.imgWrap}>
              <img src={baseUrl() + unit.data.image.url} alt="" ref={imageRef} onClick={setImageClickPosition} />
              { unit.data.products.map((pr, i) => (
                <div key={pr.id} className={css.mark} style={{ left: `${pr.markOffsetLeft}%`, top: `${pr.markOffsetTop}%` }}><div>{ i+1 }</div></div>
              ))}
              <p className={css.annotation}>Нажмите на нужное место на фото, чтобы отметить в нем товар</p>
            </div>
            <div className={css.info}>
              <GalleryImageData date={unit.data.date} madeBy={unit.data.madeBy} />
              <GalleryImageComment comment={unit.data.comment} imageId={unit.data.id} />
              <div className={css.products}>
                <h2>Товары на фото</h2>
                {
                  !unit.data.products.length &&
                  <div className={css.notProducts}><GhostIcon className={css.icon} /><p>Товары не отмечены</p></div>
                }
                <ul className={css.list}>
                  {
                    unit.data.products.map((pr, i) => (
                      <li className={css.item} key={pr.id}>
                        <p className={css.number}>{ i+1 }</p>
                        <p className={css.pos}>{ `top: ${pr.markOffsetTop}%, left: ${pr.markOffsetLeft}%` }</p>
                        <img src={baseUrl() + pr.product.images[0].url} alt="" />
                        <div className={css.description}>
                          <p className={css.path}>{ `${pr.product.product_group.catalog_section.name} > ${pr.product.product_group.name}` }</p>
                          <p className={css.name}>{ pr.product.name }</p>
                        </div>
                        <ActionButton colorType='cancel' className={css.btn} onClick={() => removeRelatedProduct(pr.id)}>Удалить</ActionButton>
                      </li>
                    ))
                  }
                </ul>
              </div>
            </div>
          </div>
        )
      }

      <ModalWindow title='Отметить товар на фото' isVisible={isModalVisible} hiddenAction={() => setIsModalVisible(false)}>
        <DebounsedInput 
          value={searchQuery}
          setValue={setSearchQuery}
          onChangeAction={searchProducts}
          placeholder='Введите часть названия товара'
          isLoading={isProductsLoading}
        />
        {
          !!products && (
            <ul className={css.list}>
              {
                products.data.queryResults.map((pr, i) => (
                  <li className={css.item} key={pr.id}>
                    <img src={baseUrl() + pr.images[0].url} alt="" />
                    <div className={css.description}>
                      <p className={css.path}>{ `${pr.product_group.catalog_section.name} > ${pr.product_group.name}` }</p>
                      <p className={css.name}>{ pr.name }</p>
                    </div>
                    <ActionButton colorType='action' className={css.btnAdd} onClick={() => setRelatedProduct(pr.id)}>Добавить</ActionButton>
                  </li>
                ))
              }
            </ul>
          )
        }
      </ModalWindow>
    </div>
  );
};
