import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { prepareHeadersBase } from "store/baseStateManager";
import { apiUrl } from "resources/api";
import { TCreactePropMarkers, TElementPropsMarkers, TGetPropsParams, TGetPropsResponse } from "./entities";

export const PropsApi = createApi({
  reducerPath: 'propApi',
  tagTypes: ['Prop'],
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl(),
    prepareHeaders: prepareHeadersBase,
  }),
  endpoints: (builder) => ({
    getProps: builder.query<TGetPropsResponse, TGetPropsParams>({
      query: ({ search, notIds }) => ({
        url: 'props',
        params: {
          'paginatiton[pageSize]': 100,
          'filters[name][$containsi]': search,
          ...notIds.reduce((obj: { [arg: string]: number }, num, ind) => (obj[`filters[id][$notIn][${ind}]`] = num, obj), {}),
        }
      }),
      providesTags: ['Prop']
    }),

    getElementProps: builder.query<TGetPropsResponse, TElementPropsMarkers>({
      query: ({ id, elementType }) => ({
        url: 'props',
        params: {
          [`filters[${elementType}]`]: id
        }
      }),
      providesTags: ['Prop']
    }),

    removeProp: builder.mutation<void, number>({
      query: (propId) => ({
        url: `props/${propId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Prop']
    }),

    createProp: builder.mutation<void, TCreactePropMarkers>({
      query: ({ name, value, measureUnit, elementId, elementType }) => ({
        url: `props`,
        method: 'POST',
        body: {
          'data': {
            name, value, measureUnit,
            [elementType]: {
              connect: [elementId]
            }
          }
        }
      }),
      invalidatesTags: ['Prop']
    }),
  }),
});