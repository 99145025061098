import React, { useEffect, useState } from 'react';
import css from './index.module.scss';
import { Input, OutdatedProductCard } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { UserSlice } from 'store';
import { SizeUnitsApi } from 'store/slices';
import { getDateLocaleString } from 'resources/helpers/dates';
import classNames from 'classnames';

type TProps = {};

const regexDate = /(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d/;

export const OutdatedPricesPage = ({  }: TProps) => {
  const dispatch = useDispatch();
  const settingsLastDate = useSelector(UserSlice.getSettings).outdatedPriceDate;

  const [dateField, setDateField] = useState(settingsLastDate || '');
  const [currentDate, setCurrentDate] = useState<Date | null>(null);
  const [isError, setErrorState] = useState(!regexDate.test(dateField));
  const [page, setPage] = useState<number>(1);

  const [ getSizeUnits, { data: sizeUnits } ] = SizeUnitsApi.useLazyGetOutdatedSizeUnitQuery();

  const onChangeDataField = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trim().slice(0, 10);
    const isValid = regexDate.test(value);

    setDateField(value);
    setErrorState(!isValid);
  }

  const onSearch = () => {
    dispatch(UserSlice.setOutdatedPriceDate(dateField));
    setCurrentDate(new Date(dateField.split('.').reverse().join('-')));
  }

  const changePage = (page: number) => () => {
    setPage(page);
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    if (currentDate) getSizeUnits({ 
      dateISO: currentDate.toISOString(),
      page,
    });
  }, [currentDate, page]);

  return (
    <div className={css.container}>
      <div className={css.bar}>
        <h1>Поиск устаревших цен</h1>
        <p className={css.description}>Введите дату в формате: 01.01.2000</p>
        <div className={css.controls}>
          <Input
            placeholder='Дата'
            value={dateField}
            onChange={onChangeDataField}
          />
          <button className={css.button} onClick={onSearch} disabled={isError}>
            { isError ? 'Некорректная дата' : 'Найти устаревшие цены' }
          </button>
        </div>
      </div>
      <div className={css.results}>
        {  
          !!sizeUnits && (
            <>
              <h3>{ 
                sizeUnits.data.length
                  ? `Цены, обновленные до ${getDateLocaleString(currentDate || '')}:`
                  : `Цен, обновленных ранее ${getDateLocaleString(currentDate || '')} не найдено`
              }</h3>
              <div className={css.list}>
                {
                  sizeUnits.data.map(item => (
                    <OutdatedProductCard 
                      key={item.id}
                      sizeUnit={item}
                      product={item.attributes.product.data}
                    />
                  ))
                }
              </div>
            </>
          )
        }
      </div>
      <div className={css.pagination}>
        {
          !!sizeUnits && (
            <>
              <h3>Всего товаров с устаревшей ценой: {sizeUnits.meta.pagination.total}</h3>
              <div className={css.list}>
                {
                  (new Array(sizeUnits.meta.pagination.pageCount)).fill(null).map((_, index) => (
                    <button
                      key={index}
                      onClick={changePage(index + 1)}
                      className={classNames(css.pageButton, { 
                        [css.current]: index + 1 === sizeUnits.meta.pagination.page
                      })}
                    >
                      { index + 1 }
                    </button>
                  ))
                }
              </div>
            </>
          )
        }
      </div>
    </div>
  );
};
