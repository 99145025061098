import React from 'react';

type TProps = {
  className?: string
}

export const RefreshIcon = ({ className = '' }: TProps) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 11.0002H7.101L7.102 10.9912C7.23257 10.3519 7.48813 9.74458 7.854 9.20423C8.39845 8.40205 9.16215 7.77339 10.054 7.39323C10.356 7.26523 10.671 7.16723 10.992 7.10223C11.6579 6.96723 12.3441 6.96723 13.01 7.10223C13.967 7.29832 14.8451 7.77164 15.535 8.46323L16.951 7.05123C16.3128 6.41287 15.5578 5.90327 14.727 5.55023C14.3033 5.37086 13.8628 5.23418 13.412 5.14223C12.4818 4.95324 11.5232 4.95324 10.593 5.14223C10.1419 5.23456 9.70101 5.37157 9.277 5.55123C8.02753 6.08133 6.95793 6.96132 6.197 8.08523C5.68489 8.84308 5.32676 9.69422 5.143 10.5902C5.115 10.7252 5.1 10.8632 5.08 11.0002H2L6 15.0002L10 11.0002ZM14 13.0002H16.899L16.898 13.0082C16.6367 14.29 15.8812 15.4173 14.795 16.1462C14.2548 16.5124 13.6475 16.768 13.008 16.8982C12.3424 17.0332 11.6566 17.0332 10.991 16.8982C10.3516 16.7677 9.74435 16.5121 9.204 16.1462C8.93862 15.9667 8.69085 15.7625 8.464 15.5362L7.05 16.9502C7.68851 17.5884 8.44392 18.0977 9.275 18.4502C9.699 18.6302 10.142 18.7672 10.59 18.8582C11.5198 19.0473 12.4782 19.0473 13.408 18.8582C15.2005 18.4862 16.7773 17.4296 17.803 15.9132C18.3146 15.156 18.6724 14.3055 18.856 13.4102C18.883 13.2752 18.899 13.1372 18.919 13.0002H22L18 9.00023L14 13.0002Z" fill="black"/>
  </svg>
);