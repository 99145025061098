import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AnnotationsApi, ProductsApi, PropsApi } from 'store/slices';
import css from './index.module.scss';
import { Annotations, MoldedProductSizeUnits, ProductInfo, ProductSizeUnits, Props, SizeUnitCard } from 'components';

type TProps = {};

export const ProductPage = ({  }: TProps) => {
  const navigate = useNavigate();
  const { productId } = useParams();
  const { data: product } = ProductsApi.useGetProductQuery(productId ? +productId : 1);

  const [ getAnnotations, { data: annotations } ] = AnnotationsApi.useLazyGetElementAnnotationsQuery();
  const [ getProps, { data: properties } ] = PropsApi.useLazyGetElementPropsQuery();

  useEffect(() => {
    if (product?.data === null) navigate('/');

    if (productId && product?.data) { 
      getAnnotations({ id: product.data.id, elementType: 'products' });
      getProps({ id: product.data.id, elementType: 'products' });
    }
  }, [productId, product]);

  return (
    <div className={css.wrap}>
      <h1><span className={css.id}>{ `id: ${product?.data?.id}` }</span>{ product?.data?.attributes.name }</h1>
      { !!product?.data && <ProductInfo product={product.data} /> }
      <div className={css.wrapAnnProps}>
        { 
          !!annotations && !!product?.data &&
          <Annotations annotations={annotations.data} ownerId={product?.data?.id} ownerType='products' /> 
        }
        { 
          !!properties && !!product?.data &&
          <Props properties={properties.data} ownerId={product?.data?.id} ownerType='products' /> 
        }
      </div>
      { 
        product?.data && (
        product.data.attributes.product_group.data.attributes.type === 'molded'
          ? <MoldedProductSizeUnits productId={product.data.id} />
          : <ProductSizeUnits productId={product.data.id} />
        )
      }
    </div>
  );
};
