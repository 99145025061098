import React, { useId } from 'react';
import css from './index.module.scss';
import classNames from 'classnames';

type TProps = {
  label: string,
  className?: string,
} & Partial<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>>;


export const CheckBox = ({ label, className = '', ...props }: TProps) => {
  const idName = useId();

  return (
    <div className={classNames(css.wrap, className)}>
      <input 
        className={css.checkbox} 
        {...props}
        type='checkbox'
        id={idName}
      />
      <label htmlFor={idName}>{ label }</label>
    </div>
  );
};
