import React, { useState } from 'react';
import css from './index.module.scss';
import { TGalleryImageComment, TGalleryImageComment_POST} from 'store/slices/galleryApi/entities';
import { GhostIcon } from 'resources/icons';
import { ActionButton } from 'components/ActionButton';
import { ModalWindow } from 'components/ModalWindow';
import { Input } from 'components/Input';
import { GalleryApi } from 'store/slices';

type TProps = {
  imageId: number,
  comment: TGalleryImageComment | null
};

export const GalleryImageComment = ({ imageId, comment }: TProps) => {
  const [isModalView, setIsModalView] = useState(false);
  const [data, setData] = useState<TGalleryImageComment_POST & { id: undefined }>({ 
    ...(comment || { name: '', city: '', text: ''}), 
    gallery_image: imageId,
    id: undefined,
  });

  const setDataField = (field: keyof Omit<TGalleryImageComment, 'gallery_image'>
    ) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setData(prev => ({ ...prev, [field]: event.target.value }));
  }

  const [ setComment ] = GalleryApi.useSetGalleryImageCommentMutation();
  const [ updateComment ] = GalleryApi.useUpdateGalleryImageCommentMutation();
  const [ removeComment ] = GalleryApi.useRemoveGalleryImageCommentMutation();

  return (
    <div className={css.wrap}>
      <div className={css.title}>
        <h2>Комментарий</h2>
        <ActionButton colorType={ !comment ? 'success' : 'action' } onClick={() => setIsModalView(true)}>
          { !comment ? 'Добавить' : 'Изменить' }
        </ActionButton>
      </div>
      {
        !comment
        ? (
          <div className={css.notComment}>
            <GhostIcon className={css.icon} />
            <p>Комментарий не добавлен</p>
          </div>
        )
        : (
          <div className={css.info}>
            <p>{ `${comment.name}, ${comment.city}` }</p>
            <p className={css.comment}>{ comment.text }</p>
          </div>
        )
      }
      <ModalWindow
        title={ (!comment ? 'Добавить' : 'Изменить') + ' комментарий' }
        isVisible={isModalView}
        hiddenAction={() => setIsModalView(false)}
      >
        <div className={css.fieldgroup}>
          <Input label='Имя' value={data.name} onChange={setDataField('name')} />
          <Input label='Город' value={data.city} onChange={setDataField('city')} />
        </div>
        <div className={css.textareagroup}>
          <p>Текст отзыва</p>
          <textarea value={data.text} onChange={setDataField('text')} />
        </div>
        <div className={css.buttongroup}>
          { !!comment && 
            <ActionButton 
              colorType='cancel' 
              onClick={() => { removeComment(comment.id); setIsModalView(false); }}
            >
              Удалить
            </ActionButton>
          }
          { !comment && 
            <ActionButton 
              colorType='success' 
              onClick={() => { setComment(data); setIsModalView(false); }}
            >
              Добавить
            </ActionButton>
          }
          { !!comment && 
            <ActionButton 
              colorType='success' 
              onClick={() => { updateComment({ data, commentId: comment.id }); setIsModalView(false); }}
            >
              Сохранить
            </ActionButton>
          }
        </div>
      </ModalWindow>
    </div>
  );
};
