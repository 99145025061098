import { createSlice } from "@reduxjs/toolkit";
import { TUserSliceState } from "./entities";
import { RequestStatuses, clientCookies } from "resources/helpers";
import { authUserAsyncBuilder } from "./asyncActions";
import { userSliceActions } from "./actions";
import { addDefaultPendingMatcher, addDefaultRejectedMatcher } from "store";
import { LSK_OUTDATED_DATE } from "resources/helpers/constants";

const initialState: TUserSliceState = {
  data: {
    name: clientCookies.getUserName() || null,
    token: clientCookies.getToken() || null,
    settings: {
      outdatedPriceDate: localStorage.getItem(LSK_OUTDATED_DATE),
    }
  },
  status: RequestStatuses.IDLE,
  error: null
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: userSliceActions,
  extraReducers: (builder) => {
    authUserAsyncBuilder(builder);

    addDefaultPendingMatcher(builder);
    addDefaultRejectedMatcher(builder);
  }
});

export const userReducer = userSlice.reducer;
export const { logoutUser, setOutdatedPriceDate } = userSlice.actions;