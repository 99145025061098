import React, { useState } from 'react';
import { TSizeUnit } from 'store/slices/sizeUnitsApi/entities';
import { CheckBox, Input } from 'components';
import css from './index.module.scss';
import { SizeUnitsApi } from 'store/slices';
import classNames from 'classnames';
import { Constants } from 'resources/helpers';

type TProps = {
  productId: number,
  sizeUnit?: TSizeUnit,
  additionalHandler?: () => void,
};

export const SizeUnitEditor = ({ productId, sizeUnit, additionalHandler }: TProps) => {
  const [isDisabled, setIsDisabled] = useState(sizeUnit?.attributes.isContract || false);
  const [basePrice, setBasePrice] = useState(Math.round((sizeUnit?.attributes.price || 0) / Constants.PRICE_GROW));
  const [data, setData] = useState<TSizeUnit['attributes']>(sizeUnit?.attributes || {
    price: 0, sale: 0, isContract: false
  });

  const [ createSizeUnit ] = SizeUnitsApi.useCreateSizeUnitMutation();
  const [ updateSizeUnit ] = SizeUnitsApi.useUpdateSizeUnitMutation();
  const [ removeSizeUnit ] = SizeUnitsApi.useRemoveSizeUnitMutation();

  const changeBasePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = +event.target.value;

    setBasePrice(value);
    setData({
      ...data,
      sale: Math.round(value * Constants.SALE_GROW),
      price: Math.round(value * Constants.PRICE_GROW),
    });
  }

  const changePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = +event.target.value;
    setData({ ...data, price: value }) 
  };

  const changeSale = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = +event.target.value;
    setData({ ...data, sale: value })
  };

  const changeIsContract = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    if (value) { 
      setData({ price: 0, sale: 0, isContract: value });
      setBasePrice(0);
      setIsDisabled(true);
    } else {
      setData({ ...data, isContract: value });
      setIsDisabled(false);
    }
  }

  const saveHandler = () => {
    if (sizeUnit) updateSizeUnit({ id: sizeUnit.id, data });
    else createSizeUnit({ productId, ...data });

    if (additionalHandler) additionalHandler();
  }

  const removeHandler = () => {
    if (sizeUnit) removeSizeUnit(sizeUnit.id);
    if (additionalHandler) additionalHandler();
  };

  return (
    <div className={css.wrap}>
      <div className={css.fields}>
        <Input 
          type='number' 
          label='Рассчетная цена' 
          value={basePrice} 
          onChange={changeBasePrice}
          disabled={isDisabled}
        />
        <CheckBox 
          label='Договорная цена?' 
          checked={data.isContract} 
          onChange={changeIsContract} 
          className={css.checkbox} 
        />
      </div>
      <div className={css.fields}>
        <Input 
          type='number' 
          label='Основная цена' 
          value={data.price} 
          onChange={changePrice} 
          disabled={isDisabled}
        />
        <Input 
          type='number' 
          label='Цена со скидкой' 
          value={data.sale} 
          onChange={changeSale} 
          disabled={isDisabled}
        />
      </div>
      <div className={css.buttons}>
        { !!sizeUnit && <button type='button' className={classNames(css.btn, css.removeBtn)} onClick={removeHandler}>Удалить</button> }
        <button type='button' className={classNames(css.btn, css.saveBtn)} onClick={saveHandler}>Сохранить</button>
      </div>
    </div>
  );
};
