import React, { useState } from 'react';
import css from './index.module.scss';
import { DebounsedInput } from 'components';
import { ProductsApi } from 'store/slices';
import { normalizeSearchQuery } from 'resources/helpers/searchQueryConfiguration';
import { SearchedProductCard } from 'components/SearchedProductCard';

type TProps = {};

export const SearchPage = ({  }: TProps) => {
  const [search, setSearch] = useState('');
  const [getSearchRequest, { data: results }] = ProductsApi.useLazySearchProductsQuery();

  const searchAction = async (value: string) => {
    await getSearchRequest(normalizeSearchQuery(value));
    setSearch('');
  }

  return (
    <div className={css.container}>
      <div className={css.bar}>
        <h1>Поиск по каталогу</h1>
        <div className={css.controls}>
          <DebounsedInput
            placeholder='Введите текст...'
            value={search}
            setValue={setSearch}
            onChangeAction={searchAction}
          />
        </div>
      </div>
      <div className={css.results}>
        { 
          !!results && (
            <>
              { !!results.data.queryResults.length && <h3>Найдено по запросу:</h3>}
              <div className={css.list}>
                {
                  results.data.queryResults.map(item => (
                    <SearchedProductCard key={item.id} product={item} />
                  ))
                }
              </div>
              { !!results.data.keywordsResults.length && <h3>Возможно вы искали:</h3>}
              <div className={css.list}>
                {
                  results.data.keywordsResults.map(item => (
                    <SearchedProductCard key={item.id} product={item} />
                  ))
                }
              </div>
              { 
                !results.data.queryResults.length && 
                !results.data.keywordsResults.length &&
                <h3>По вашему запросу ничего не найдено...</h3>
              }
            </>
          )
        }
      </div>
    </div>
  );
};
