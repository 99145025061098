import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAppDispatch, UserSlice } from 'store';
import { TAuthParams } from 'store/slices/user/entities';
import { ActionButton, Input } from 'components';
import css from './index.module.scss';


export const AuthPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const token = useSelector(UserSlice.getToken);

  const [formData, setFormData] = useState<TAuthParams>({ identifier: '', password: '' });

  const onFieldValueChange = (field: keyof TAuthParams) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({ ...prev, [field]: event.target.value }));
  };

  const onFormFinish = () => {
    dispatch(UserSlice.authUserAsync(formData));
  };

  useEffect(() => {
    if (token) {
      navigate('/');
    }
  }, [token]);

  return (
    <div className={css.wrap}>
      <h1 className={css.title}>Авторизация</h1>
      <div className={css.form}>
        <Input 
          label='Логин' 
          placeholder='username' 
          value={formData.identifier}
          onChange={onFieldValueChange('identifier')}
        />
        <Input 
          label='Пароль' 
          type='password' 
          placeholder='********' 
          value={formData.password}
          onChange={onFieldValueChange('password')}  
        />
        <ActionButton 
          colorType='success' 
          onClick={onFormFinish}
          className={css.button}
        >
            Войти
        </ActionButton>
      </div>
    </div>
  );
};
