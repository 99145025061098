import React from 'react';
import css from './index.module.scss';
import { TSizeUnit } from 'store/slices/sizeUnitsApi/entities';
import { TProduct } from 'store/slices/productsApi/entities';
import { baseUrl } from 'resources/api';
import { SizeUnitCard } from 'components/SizeUnitCard';
import { getDateLocaleString } from 'resources/helpers/dates';
import { SizeUnitsApi } from 'store/slices';

type TProps = {
  sizeUnit: TSizeUnit,
  product: TProduct,
};

export const OutdatedProductCard = ({ sizeUnit, product }: TProps) => {
  const [updateSizeUnit, { isLoading }] = SizeUnitsApi.useUpdateSizeUnitMutation();

  const savePrice = () => {
    updateSizeUnit({
      id: sizeUnit.id,
      data: {
        ...sizeUnit.attributes,
        updatedAt: (new Date()).toISOString(),
      }
    });
  }

  return (
    <div className={css.wrap}>
      { !isLoading && (
        <p className={css.lastUpdate}>
          { `Обновлено: ${getDateLocaleString(sizeUnit.attributes.updatedAt || '')}` }
        </p>
      )}
      <div className={css.imgContainer}>
        <img src={baseUrl() + product.attributes.images.data[0].attributes.url} alt="" />
      </div>
      <p className={css.name}>{ product.attributes.name }</p>
      <SizeUnitCard sizeUnit={sizeUnit} productId={product.id} withoutId />
      <button className={css.button} onClick={savePrice}>Пометить цену неустарешей</button>
      { isLoading && (
        <div className={css.loader}><p>Сохранение...</p></div>
      ) }
    </div>
  );
};
