import React from 'react';
import classNames from 'classnames';
import css from './index.module.scss';

type TProps = {
  label?: string,
  refLink?: React.RefObject<HTMLInputElement>,
} & Partial<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>>;


export const Input = ({ label = '', className = '', refLink, ...props }: TProps) => {

  const inputElement = () => (
    <input 
      className={classNames(css.input, className)} 
      {...props}
      ref={refLink}
    />
  );

  return (
    <>
      {
        !!label
          ? (
            <label className={css.label}>
              <span>{ label }</span>
              { inputElement() }
            </label>
            )
          : inputElement()
      }
    </>
  );
};
