import UniversalCookie from 'universal-cookie';

class Cookies extends UniversalCookie {
  setToken = (token: string) => {
    const expires = new Date();
    expires.setDate(expires.getDate() + 7);
    this.set('user_token', token, { path: '/', expires });
  };

  deleteToken = () => {
    this.remove('user_token');
  };

  getToken = (): string | undefined => this.get('user_token');

  setUserName = (name: string) => {
    const expires = new Date();
    expires.setDate(expires.getDate() + 7);
    this.set('user_name', name, { path: '/', expires });
  };

  deleteUserName = () => {
    this.remove('user_name');
  };

  getUserName = (): string | undefined => this.get('user_name');
}

export const clientCookies = new Cookies();
