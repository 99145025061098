import React from "react";
import { Navigate } from "react-router-dom";
import { clientCookies } from "resources/helpers";

type TProps = {
  children: JSX.Element;
};

export const ProtectedRoute = ({ children }: TProps) => {
  const name = clientCookies.getUserName();
  
  if (!name) {
    return <Navigate to="/auth" />;
  }

  return children;
};