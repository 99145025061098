import React from 'react';
import css from './index.module.scss';
import classNames from 'classnames';

type TProps = {
  isLoading?: boolean,
  onClick?: () => any,
  className?: string,
  colorType?: 'default' | 'action' | 'cancel' | 'success',
  disabled?: boolean,
} & React.PropsWithChildren;

export const ActionButton = ({ children, colorType = 'action', className, onClick, isLoading = false, disabled = false }: TProps) => {

  return (
    <button
      type='button'
      onClick={onClick}
      className={classNames(css.btn, css[colorType], className)}
      disabled={disabled}
    >
      { 
        isLoading 
          ? <div className={css.ldsEllipsis}><div></div><div></div><div></div><div></div></div> 
          : children  
      }
    </button>
  );
};
