import React from 'react';
import css from './index.module.scss';

type TProps = {
  number: number,
  title: string,
  subtitle?: string, 
};

export const SectionDivider = ({ number, title, subtitle }: TProps) => {

  return (
    <div className={css.wrap}>
      <p className={css.number}>{ number < 10 ? `0${number}` : number }</p>
      <div className={css.content}>
        <h2 className={css.title}>{ title }</h2>
        <p className={css.subtitle}>{ subtitle }</p>
      </div>
    </div>
  );
};
