import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AnnotationsApi, ProductGroupsApi, ProductsApi, PropsApi } from 'store/slices';
import { ProductGroupInfo, Annotations, Props, ProductCard } from 'components';
import css from './index.module.scss';

type TProps = {};

export const ProductGroupPage = ({  }: TProps) => {
  const navigate = useNavigate();
  const { productGroupKey } = useParams();
  const { data: productGroup } = ProductGroupsApi.useGetProductGroupWithKeyQuery(productGroupKey || '');
  const [getAnnotations, { data: annotations }] = AnnotationsApi.useLazyGetElementAnnotationsQuery();
  const [getProps, { data: properties }] = PropsApi.useLazyGetElementPropsQuery();
  const [getProducts, { data: products }] = ProductsApi.useLazyGetProductsInProductGroupQuery();

  useEffect(() => {
    if (productGroup === null) navigate('/');

    if (productGroup) { 
      getAnnotations({ id: productGroup.id, elementType: 'product_groups' });
      getProps({ id: productGroup.id, elementType: 'product_groups' });
      getProducts({ productGroupId: productGroup.id });
    }
  }, [productGroup]);

  return (
    <>
      <h1><span className={css.id}>{ `id: ${productGroup?.id}` }</span>{ productGroup?.attributes.name }</h1>
      { !!productGroup && <ProductGroupInfo productGroup={productGroup} /> }
      <div className={css.wrapAnnProps}>
        { 
          !!annotations && !!productGroup?.id && 
          <Annotations 
            annotations={annotations.data}
            ownerType='product_groups'
            ownerId={productGroup?.id}
          /> 
        }
        { 
          !!properties && !!productGroup?.id && 
          <Props 
            properties={properties.data}
            ownerType='product_groups'
            ownerId={productGroup?.id}
          /> 
        }
      </div>
      <div className={css.products}>
        { !!products && productGroupKey &&
          <>
            <h2>Товары в группе</h2>
            <div className={css.grid}>
              {
                products.data.map(
                  product => <ProductCard key={product.id} productGroupKey={productGroupKey} product={product} />
                )
              }
            </div>
          </>
        }
      </div>
    </>
  );
};
