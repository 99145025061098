import React from 'react';
import css from './index.module.scss';
import { GalleryApi } from 'store/slices';
import { baseUrl } from 'resources/api';
import classNames from 'classnames';
import { BoxIcon } from 'resources/icons';
import { Link } from 'react-router-dom';

type TProps = {};

export const GalleryPage = ({  }: TProps) => {
  const { data: images, isFetching } = GalleryApi.useGetGalleryQuery();

  return (
    <div className={css.wrap}>
      <h1>Галерея работ</h1>
      { isFetching && <p className={css.loading}>Ожидайте, идет загрузка...</p> }
      <div className={css.list}>
        {
          images && images.data.map(unit => (
            <Link key={unit.id} className={css.imgWrap} to={'/gallery/' + unit.id}>
              <p className={css.id}>{ `id: ${unit.id}` }</p>
              <img src={baseUrl() + unit.attributes.image.data.attributes.url} alt="" />
              <p className={classNames(css.amountProducts, { [css.notProducts]: !unit.attributes.products.data.length })}>
                <BoxIcon className={css.icon} />
                <span>{ `${unit.attributes.products.data.length}` }</span>
              </p>
            </Link>
          ))
        }
      </div>
    </div>
  );
};
