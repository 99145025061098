import React, { useState } from 'react';
import css from './index.module.scss';
import { TMoldedSizeGroup } from 'store/slices/moldedSizeGroupsApi/entities';
import { GroupIcon } from 'resources/icons';
import { ActionButton } from 'components/ActionButton';
import { ModalWindow, MoldedSizeGroupEditor } from 'components';
import { MoldedSizeGroupsApi } from 'store/slices';

type TProps = {
  productId: number,
  sizeGroup: TMoldedSizeGroup,
};


const MoldedGroupProp = ({ name, value }: { name: string, value: string }) => {
  return (
    <div className={css.prop}>
      <p className={css.name}>{ name }</p>
      <p className={css.value}>{ value }</p>
    </div>
  );
}

export const MoldedSizeGroupInfo = ({ productId, sizeGroup }: TProps) => {
  const { startLength, finishLength, price, sale, size_units } = sizeGroup.attributes;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [ removeSizeGroup ] = MoldedSizeGroupsApi.useRemoveGroupMutation();

  const removeHandler = () => removeSizeGroup(sizeGroup.id);

  const showModal = () => setIsModalVisible(true);
  const hiddenModal = () => setIsModalVisible(false); 

  return (
    <>
      <div className={css.wrap}>
        <div className={css.info}>
          <GroupIcon className={css.icon} />
          <MoldedGroupProp name='Длина от' value={ `${startLength.toFixed(1)} м` } />
          <MoldedGroupProp name='Длина до' value={ `${finishLength.toFixed(1)} м` } />
          <MoldedGroupProp name='Основная цена' value={ `${price} ₽ / пог.м` } />
          <MoldedGroupProp name='Со скидкой' value={ `${sale} ₽ / пог.м` } />

          <div className={css.buttons}>
            <ActionButton 
              className={css.btn} 
              colorType='action'
              onClick={showModal}
            >
              Изменить
            </ActionButton>
            <ActionButton 
              className={css.btn} 
              colorType='cancel'
              onClick={removeHandler}
            >
              Удалить
            </ActionButton>
          </div>
        </div>
        <div className={css.units}>
          {
            size_units.data.map(unit => (
              <div className={css.unit} key={unit.id}>
                <MoldedGroupProp name='Длина' value={ `${unit.attributes.moldedLength.toFixed(1)}м` } />
                <MoldedGroupProp name='Цена / Со скидкой' value={ `${unit.attributes.price} / ${unit.attributes.sale} ₽` } />
              </div>
            ))
          }
        </div>
      </div>

      <ModalWindow
        title='Редактирование размерной группы'
        isVisible={isModalVisible}
        hiddenAction={hiddenModal}
      >
        <MoldedSizeGroupEditor sizeGroup={sizeGroup} productId={productId} additionalHandler={hiddenModal} />
      </ModalWindow>
    </>
  );
};
