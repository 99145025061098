import { TSearchProductParams } from "store/slices/productsApi/entities";

const removeMoreThanOneSpace = (query: string) => {
  return query.replace(/\s+/g, ' ');
}

const removeEverythingExceptLettersAndSpacesAndQuotes = (query: string) => {
  return query.replace(/[^a-zа-яё\s\"]/gi, '');
}

const removeSpaceInBeginAndEndOfQuery = (query: string) => {
  if (query[0] === ' ') query = query.slice(1);
  if (query[query.length - 1] === ' ') query = query.slice(0, query.length - 1); 
  return query;
}

const PorterRuComponents = {
  perfectiveground: /((ив|ивши|ившись|ыв|ывши|ывшись)|((?<=[ая])(в|вши|вшись)))$/,
  reflexive: /(с[яь])$/,
  adjective: /(ее|ие|ые|ое|ими|ыми|ей|ий|ый|ой|ем|им|ым|ом|его|ого|ему|ому|их|ых|ую|юю|ая|яя|ою|ею)$/,
  participle: /((ивш|ывш|ующ)|((?<=[ая])(ем|нн|вш|ющ|щ)))$/,
  verb: /((ила|ыла|ена|ейте|уйте|ите|или|ыли|ей|уй|ил|ыл|им|ым|ен|ило|ыло|ено|ят|ует|уют|ит|ыт|ены|ить|ыть|ишь|ую|ю)|((?<=[ая])(ла|на|ете|йте|ли|й|л|ем|н|ло|но|ет|ют|ны|ть|ешь|нно)))$/,
  noun: /(а|ев|ов|ие|ье|е|иями|ями|ами|еи|ии|и|ией|ей|ой|ий|й|иям|ям|ием|ем|ам|ом|о|у|ах|иях|ях|ы|ь|ию|ью|ю|ия|ья|я)$/,
  rvre: /^(.*?[аеиоуыэюя])(.*)$/,
  derivational: /.*[^аеиоуыэюя]+[аеиоуыэюя].*ость?$/,
  der: /ость?$/,
  superlative: /(ейше|ейш)$/,
  и: /и$/,
  ь: /ь$/,
  нн: /нн$/,
}
  
const PorterRuChange = (word: string) => {
  let m, pre, rv, temp;
    word = word.toLowerCase().replace(/ё/g, 'е');
    if (m = word.match(PorterRuComponents.rvre)) {
      pre = m[1];
      rv = m[2];
      temp = rv.replace(PorterRuComponents.perfectiveground,'');
      if (temp==rv) {
        rv = rv.replace(PorterRuComponents.reflexive,"");
        temp = rv.replace(PorterRuComponents.adjective,"");
        if (temp!=rv) {
          rv = temp;
          rv = rv.replace(PorterRuComponents.participle,"");
        } else {
          temp = rv.replace(PorterRuComponents.verb,"");
          if (temp==rv) {
            rv = rv.replace(PorterRuComponents.noun,"");
          } else {
            rv = temp;
          }
        }
      } else {
        rv = temp;
      }
      rv = rv.replace(PorterRuComponents.и,"");
      if (PorterRuComponents.derivational.test(rv)) {
        rv = rv.replace(PorterRuComponents.der,"");
      }
      rv = ((temp = rv.replace(PorterRuComponents.ь,""))!=rv) ? 
          temp : rv.replace(PorterRuComponents.superlative,'').replace(PorterRuComponents.нн,"н");
      word = pre + rv;
    }
  return word;
}

const CategoriesNames = ['a', 'b', 'c', 'bc', 'а', 'б', 'в', 'с', 'бс', 'вс'];

const removeShortKeywordsWhichIsNotCategory = (keywords: string[]) => {
  return keywords.filter(word => word.length > 2 || CategoriesNames.includes(word));
}


export const normalizeSearchQuery = (query: string): TSearchProductParams => {
  query = query.toLowerCase();
  query = removeEverythingExceptLettersAndSpacesAndQuotes(query);
  query = removeMoreThanOneSpace(query);
  query = removeSpaceInBeginAndEndOfQuery(query);

  let keywords = query.split(' ');
  keywords = keywords.map(word => PorterRuChange(word));
  keywords = removeShortKeywordsWhichIsNotCategory(keywords);
  keywords = keywords.sort((w1, w2) => w1.length - w2.length);

  return { query, keywords }
}

export type TPageLabelInfo = { 
  path: string, 
  name: string 
}

