import { RequestStatuses, clientCookies } from "resources/helpers";
import { TUserSliceState } from "./entities";
import { PayloadAction } from "@reduxjs/toolkit";
import { LSK_OUTDATED_DATE } from "resources/helpers/constants";

export const userSliceActions = {

  logoutUser (state: TUserSliceState): TUserSliceState {
    clientCookies.deleteToken();
    clientCookies.deleteUserName();
    return {
      ...state,
      status: RequestStatuses.IDLE,
      error: null,
      data: {
        ...state.data,
        name: null,
        token: null,
      }
    };
  },

  setOutdatedPriceDate (state: TUserSliceState, { payload }: PayloadAction<string | null>) {
    state.data.settings.outdatedPriceDate = payload;
    
    if (payload) localStorage.setItem(LSK_OUTDATED_DATE, payload);
    else localStorage.removeItem(LSK_OUTDATED_DATE);
  }
}