import React, { useState } from 'react';
import { PlusIcon } from 'resources/icons';
import { ModalWindow, MoldedSizeGroupEditor, MoldedSizeGroupInfo } from 'components';
import css from './index.module.scss';
import { MoldedSizeGroupsApi } from 'store/slices';

type TProps = {
  productId: number,
};

export const MoldedProductSizeUnits = ({ productId }: TProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  
  const { data: sizeGroups } = MoldedSizeGroupsApi.useGetGroupsForProductQuery(productId);

  const showModal = () => setIsModalVisible(true);
  const hiddenModal = () => setIsModalVisible(false); 

  return (
    <>
      <div className={css.wrap}>
        <h2>Размерные группы</h2>
        <div className={css.items}>
          {
            sizeGroups?.data.map(group => (
              <MoldedSizeGroupInfo productId={productId} sizeGroup={group} key={group.id} />
            ))
          }
          <button type='button' className={css.addBtn} onClick={showModal}>
            <PlusIcon className={css.icon} />
          </button>
        </div>
      </div>

      <ModalWindow
        title='Создание размерной группы'
        isVisible={isModalVisible}
        hiddenAction={hiddenModal}
      >
        <MoldedSizeGroupEditor productId={productId} additionalHandler={hiddenModal} />
      </ModalWindow>
    </>
  );
};
