import React from 'react';
import { Link } from 'react-router-dom';
import { TProduct } from 'store/slices/productsApi/entities';
import { baseUrl } from 'resources/api';
import css from './index.module.scss';

type TProps = {
  productGroupKey: string,
  product: TProduct,
};

export const ProductCard = ({ product, productGroupKey }: TProps) => {
  const image = product.attributes.images.data[0].attributes.url;
  const { name, minPrice } = product.attributes;

  return (
    <Link className={css.wrap} to={`/${productGroupKey}/${product.id}`}>
      <p className={css.id}>{ `id: ${product.id}` }</p>
      <div className={css.img} style={{ backgroundImage: `url(${ baseUrl() + image })` }} />
      <div className={css.content}>
        <h3 className={css.title}>{ name }</h3>
      </div>
    </Link>
  );
};
