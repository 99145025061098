import React from 'react';
import { Link } from 'react-router-dom';
import { TProductGroupSmall } from 'store/slices/productGroupsApi/entities';
import css from './index.module.scss';
import { baseUrl } from 'resources/api';

type TProps = {
  productGroup: TProductGroupSmall;
};

export const ProductGroupCard = ({ productGroup }: TProps) => {

  return (
    <Link className={css.wrap} to={productGroup.attributes.key}>
      <p className={css.id}>{ `id: ${productGroup.id}` }</p>
      <div 
        className={css.img} 
        style={{ backgroundImage: `url(${baseUrl() + productGroup.attributes.image.data.attributes.url})` }} 
      />
      <div className={css.content}>
        <h3 className={css.title}>{ productGroup.attributes.name }</h3>
        <p className={css.amount}>{ `Товаров ${productGroup.attributes.amountProducts}` }</p>
      </div>
    </Link>
  );
};
