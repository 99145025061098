import React from 'react';

type TProps = {
  className?: string
}

export const GhostIcon = ({ className = '' }: TProps) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 2C7.037 2 3 6.038 3 11V19H3.051C3.296 20.691 4.741 22 6.5 22C7.674 22 8.574 21.583 9.172 20.826C9.54388 21.1994 9.98605 21.4954 10.473 21.697C10.9599 21.8985 11.4819 22.0017 12.0089 22.0003C12.5359 21.999 13.0574 21.8934 13.5433 21.6894C14.0293 21.4854 14.47 21.1872 14.84 20.812C15.441 21.574 16.344 22 17.5 22C19.43 22 21 20.43 21 18.5V11C21 6.038 16.963 2 12 2ZM19 18.5C19 19.327 18.327 20 17.5 20C17.051 20 16 20 16 18V17H14V18C14 19.103 13.103 20 12 20C10.897 20 10 19.103 10 18V17H8V18C8 19.845 7.226 20 6.5 20C5.673 20 5 19.327 5 18.5V11C5 7.14 8.141 4 12 4C15.859 4 19 7.14 19 11V18.5Z" fill="black"/>
    <path d="M9 12C10.1046 12 11 11.1046 11 10C11 8.89543 10.1046 8 9 8C7.89543 8 7 8.89543 7 10C7 11.1046 7.89543 12 9 12Z" fill="black"/>
    <path d="M15 12C16.1046 12 17 11.1046 17 10C17 8.89543 16.1046 8 15 8C13.8954 8 13 8.89543 13 10C13 11.1046 13.8954 12 15 12Z" fill="black"/>
  </svg>
);