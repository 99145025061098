import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { 
  UserSlice,
  ProductGroupsApi,
  AnnotationsApi,
  PropsApi,
  ProductsApi,
  SizeUnitsApi,
  MoldedSizeGroupsApi,
  RefreshApi,
  GalleryApi,
} from "./slices";

const rootReducer = combineReducers({
  user: UserSlice.userReducer,
  [ProductGroupsApi.reducerPath]: ProductGroupsApi.reducer,
  [AnnotationsApi.reducerPath]: AnnotationsApi.reducer,
  [PropsApi.reducerPath]: PropsApi.reducer,
  [ProductsApi.reducerPath]: ProductsApi.reducer,
  [SizeUnitsApi.reducerPath]: SizeUnitsApi.reducer,
  [MoldedSizeGroupsApi.reducerPath]: MoldedSizeGroupsApi.reducer,
  [RefreshApi.reducerPath]: RefreshApi.reducer,
  [GalleryApi.reducerPath]: GalleryApi.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: getDefaultMiddleware => getDefaultMiddleware()
    .concat(ProductGroupsApi.middleware)
    .concat(AnnotationsApi.middleware)
    .concat(PropsApi.middleware)
    .concat(ProductsApi.middleware)
    .concat(SizeUnitsApi.middleware)
    .concat(MoldedSizeGroupsApi.middleware)
    .concat(RefreshApi.middleware)
    .concat(GalleryApi.middleware)
});

export type TState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();