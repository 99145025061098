import React, { useState } from 'react';
import { TSizeUnit } from 'store/slices/sizeUnitsApi/entities';
import { AnnotationsApi, PropsApi } from 'store/slices';
import { Annotations, ModalWindow, Props, SizeUnitEditor } from 'components';
import css from './index.module.scss';
import classNames from 'classnames';
import { Constants } from 'resources/helpers';

type TProps = {
  sizeUnit: TSizeUnit,
  productId: number,
  withoutId?: boolean,
};

export const SizeUnitCard = ({ sizeUnit, productId, withoutId = false }: TProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { price, sale, isContract } = sizeUnit.attributes;

  const { data: annotations } = AnnotationsApi.useGetElementAnnotationsQuery({ 
    elementType: 'size_units', id: sizeUnit.id 
  });

  const { data: properties } = PropsApi.useGetElementPropsQuery({
    elementType: 'size_units', id: sizeUnit.id 
  });


  const showModal = () => setIsModalVisible(true);
  const hiddenModal = () => setIsModalVisible(false);

  return (
    <>
      <button className={css.wrap} type='button' onClick={showModal}>
        { !withoutId && <p className={css.id}>{ `id: ${sizeUnit.id}` }</p> }
        <div className={css.props}>
          {
            properties?.data.map(prop => (
              <div key={prop.id} className={css.item}>
                <p className={css.name}>{ prop.attributes.name }</p>
                <p className={css.value}>{ `${prop.attributes.value} ${prop.attributes.measureUnit || ''}` }</p>
              </div>
            ))
          }
        </div>
        <div className={css.prices}>
          {
            isContract
              ? <p className={css.price}>{ 'Договорная' }</p>
              : <>
                <p className={css.price}>{ `${price} ₽` }</p>
                <p className={css.sale}>{ `${sale} ₽` }</p>
              </>
          }
          <div className={css.basePriceWrap}>
            <p className={css.basePrice}>{ `${Math.round((sizeUnit?.attributes.price || 0) / Constants.PRICE_GROW)} ₽` }</p>
          </div>
        </div>
      </button>

      <ModalWindow
        isVisible={isModalVisible}
        hiddenAction={hiddenModal}
        title='Размерная единица'
      >
        <SizeUnitEditor 
          productId={productId} 
          sizeUnit={sizeUnit}
          additionalHandler={hiddenModal}
        />
        <div className={css.wrapAnnProps}>
          <Annotations 
            annotations={annotations?.data || []} 
            ownerType='size_units' 
            ownerId={sizeUnit.id}
          />
          <Props 
            properties={properties?.data || []} 
            ownerType='size_units' 
            ownerId={sizeUnit.id}
          />
        </div>
      </ModalWindow>
    </>
  );
};
