import React, { useState } from 'react';
import { TAnnotation } from 'store/slices/annotationsApi/entities';
import { AnnotationsApi } from 'store/slices';
import { InfoIcon, BoxIcon, SearchIcon, GhostIcon } from 'resources/icons';
import { ActionButton, DebounsedInput, ModalWindow } from 'components';
import css from './index.module.scss';

type TProps = {
  annotations: TAnnotation[],
  ownerType: 'product_groups' | 'products' | 'size_units',
  ownerId: number,
};

export const Annotations = ({ annotations, ownerType, ownerId }: TProps) => {
  const [inputValue, setInputValue] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [
    connect, { isLoading: isConnectLoading }
  ] = AnnotationsApi.useConnectAnnotationMutation();

  const [
    disconnect, { isLoading: isDisconnectLoading }
  ] = AnnotationsApi.useDisconnectAnnotationMutation();
  
  const [
    getAnnotations, { data: freeAnnotations, isLoading: isAnnotationsLoading }
  ] = AnnotationsApi.useLazyGetAnnotationsQuery();


  const findAnnotationsHandler = (search: string) => {
    getAnnotations({ search, notIds: annotations.map(ann => ann.id) }).unwrap();
  };

  const addHandler = (id: number) => async () => {
    await connect({ 
      annotationId: id,
      elementId: ownerId,
      elementType: ownerType,
    }).unwrap();
  }

  const removeHandler = (id: number) => async () => {
    await disconnect({ 
      annotationId: id,
      elementId: ownerId,
      elementType: ownerType,
    }).unwrap();
  }

  const hiddenModal = () => setIsModalVisible(false);
  const showModal = () => setIsModalVisible(true);


  return (
    <div className={css.wrap}>
      <h2>Примечания</h2>
      <ActionButton className={css.findBtn} onClick={showModal}>
        <SearchIcon className={css.findIcon} />
      </ActionButton>
      {
        !annotations.length && <div className={css.notItems}><GhostIcon /></div>
      }
      {
        annotations.map(ann => (
          <div key={ann.id} className={css.item}>
            <div className={css.content}>
              { ann.attributes.type === 'default' && <InfoIcon className={css.icon} /> }
              { ann.attributes.type === 'equipment' && <BoxIcon className={css.icon} /> }

              <p className={css.text}>{ ann.attributes.text }</p>
            </div>
            <ActionButton
              isLoading={isDisconnectLoading}
              onClick={removeHandler(ann.id)}
              className={css.removeBtn} 
              colorType='cancel'
            >
              Удалить
            </ActionButton>
          </div>
        ))
      }

      <ModalWindow isVisible={isModalVisible} hiddenAction={hiddenModal} title='Добавить примечания'>
        <DebounsedInput
          value={inputValue}
          setValue={setInputValue}
          onChangeAction={findAnnotationsHandler}
          placeholder='Введите часть текста примечания'
          isLoading={isAnnotationsLoading}
        />
        {
          freeAnnotations?.data.map(ann => (
            <div key={ann.id} className={css.item}>
              <div className={css.content}>
                { ann.attributes.type === 'default' && <InfoIcon className={css.icon} /> }
                { ann.attributes.type === 'equipment' && <BoxIcon className={css.icon} /> }

                <p className={css.text}>{ ann.attributes.text }</p>
              </div>
              <ActionButton
                isLoading={isConnectLoading}
                onClick={addHandler(ann.id)}
                className={css.removeBtn}
              >
                Добавить
              </ActionButton>
            </div>
          ))
        }
      </ModalWindow>
    </div>
  );
};
