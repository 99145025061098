import React from 'react';
import css from './index.module.scss';
import { ActionButton } from 'components/ActionButton';

type TProps = {
  title: string, 
  isVisible: boolean,
  hiddenAction: () => void;
} & React.PropsWithChildren;

export const ModalWindow = ({ isVisible, hiddenAction, title, children }: TProps) => {
  if (!isVisible) return <></>;

  return (
    <div className={css.wrap}>
      <div className={css.window}>
        <div className={css.title}>
          <h2>{ title }</h2>
          <ActionButton colorType='default' onClick={hiddenAction}>Закрыть</ActionButton>
        </div>
        {
          children
        }
      </div>
    </div>
  );
};
