import axios, { InternalAxiosRequestConfig } from "axios";
import { clientCookies } from "resources/helpers";

export const baseUrl = () => (
  'https://api.ecotrees-shop.ru'
);

export const apiUrl = () => (
  'https://api.ecotrees-shop.ru/api'
);

export const $api = axios.create({
  timeout: 10000,
  baseURL: apiUrl(),
  headers: {
    'Content-Type': 'application/json',
    Authorization: '',
  },
});

$api.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const token = clientCookies.getToken();

    if (config.headers && token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  }
);