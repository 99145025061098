import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { apiUrl } from "resources/api";
import { prepareHeadersBase } from "store/baseStateManager";
import { TGalleryImageComment_POST, TGalleryImageComment_PUT, TGalleryList, TGalleryUnit, TRelatedProduct_POST, TSearchProductsForRelateResult } from "./entities";

export const GalleryApi = createApi({
  reducerPath: 'galleryApi',
  tagTypes: ['Gallery'],
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl(),
    prepareHeaders: prepareHeadersBase,
  }),
  endpoints: (builder) => ({
    getGallery: builder.query<TGalleryList, void>({
      query: () => ({
        url: 'gallery-images',
        params: {
          'populate[0]': 'image',
          'populate[1]': 'products',
          'pagination[pageSize]': 100,
        }
      }),
      providesTags: ['Gallery']
    }),

    getGalleryUnit: builder.query<TGalleryUnit, number>({
      query: (id: number) => ({
        url: `gallery-images/id/${id}`,
      }),
      providesTags: ['Gallery']
    }),

    setGalleryImageComment: builder.mutation<void, TGalleryImageComment_POST>({
      query: (data: TGalleryImageComment_POST) => ({
        url: 'gallery-image-comments',
        method: 'post',
        body: { data },
      }),
      invalidatesTags: ['Gallery']
    }),

    updateGalleryImageComment: builder.mutation<void, { data: TGalleryImageComment_PUT, commentId: number }>({
      query: ({ data, commentId }) => ({
        url: `gallery-image-comments/${commentId}`,
        method: 'put',
        body: { data },
      }),
      invalidatesTags: ['Gallery']
    }),

    removeGalleryImageComment: builder.mutation<void, number>({
      query: (commentId: number) => ({
        url: `gallery-image-comments/${commentId}`,
        method: 'delete',
      }),
      invalidatesTags: ['Gallery']
    }),

    setImageRelatedProduct: builder.mutation<void, TRelatedProduct_POST>({
      query: (data: TRelatedProduct_POST) => ({
        url: 'image-related-products',
        method: 'post',
        body: { data }
      }),
      invalidatesTags: ['Gallery']
    }),

    removeImageRelatedProduct: builder.mutation<void, number>({
      query: (relatedProductId: number) => ({
        url: `image-related-products/${relatedProductId}`,
        method: 'delete',
      }),
      invalidatesTags: ['Gallery']
    }),

    searchProductsForRelate: builder.query<TSearchProductsForRelateResult, string>({
      query: (query: string) => ({
        url: 'products/search',
        method: 'get',
        params: { query, keywords: '[]' },
      })
    }),
  }),
});