import { ActionReducerMapBuilder, AnyAction, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { BaseQueryApi } from "@reduxjs/toolkit/dist/query";
import { MaybePromise } from "@reduxjs/toolkit/dist/query/tsHelpers";
import { RequestStatuses, clientCookies } from "resources/helpers";

export type TBaseState = {
  status: RequestStatuses,
  error: SerializedError | null,
}

export const addDefaultPendingMatcher = (builder: ActionReducerMapBuilder<TBaseState>) => {
  builder
    .addMatcher(
      (action: AnyAction) => action.type.endsWith('/pending'),
      (state) => {
        state.status = RequestStatuses.LOADING;
        state.error = null;
      }
    )
}

export const addDefaultRejectedMatcher = (builder: ActionReducerMapBuilder<TBaseState>) => {
  builder
    .addMatcher(
      (action: AnyAction) => action.type.endsWith('/rejected'),
      (state, { payload }: PayloadAction<SerializedError>) => {
        state.status = RequestStatuses.FAILURE;
        state.error = payload;
      }
    )
}

export const prepareHeadersBase = (headers: Headers): Headers => {
  headers.set('Content-Type', 'application/json');

  const token = clientCookies.getToken();
  if (token) headers.set('Authorization', `Bearer ${token}`);
  
  return headers
}