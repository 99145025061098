import React, { useEffect, useState } from 'react';
import { useDebounce } from 'resources/helpers';
import css from './index.module.scss';
import { SearchIcon } from 'resources/icons';

type TProps = {
  value: string,
  setValue: (arg: string) => void,
  onChangeAction: (arg: string) => void,
  lengthForAction?: number,
  isLoading?: boolean,
} & React.InputHTMLAttributes<HTMLInputElement>;

export const DebounsedInput = ({ 
  value, setValue, onChangeAction, lengthForAction = 2, isLoading = false, ...attr 
}: TProps) => {

  const debouncedValue = useDebounce(value, 1000);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => 
    setValue(event.target.value);

  useEffect(() => {
    if (debouncedValue.length >= lengthForAction) {
      onChangeAction(debouncedValue);
    }
  }, [debouncedValue]);


  return (
    <div className={css.wrap}>
      <SearchIcon className={css.icon} />
      <input
        {...attr}
        value={value}
        onChange={onChange}
        className={css.input}
      />
      { isLoading && <div className={css.ldsEllipsis}><div></div><div></div><div></div><div></div></div>}
    </div>
  );
};
