import { ActionReducerMapBuilder, SerializedError, createAsyncThunk, miniSerializeError } from "@reduxjs/toolkit";
import { TAuthParams, TAuthResponse, TUserSliceState } from "./entities";
import { userService } from "./service";
import { RequestStatuses, clientCookies } from "resources/helpers";

export const authUserAsync = createAsyncThunk<TAuthResponse, TAuthParams, { rejectValue: SerializedError }>(
  'user/authUser',
  async ({ identifier, password }: TAuthParams, { rejectWithValue }) => {
    try {
      const { data } = await userService.generateToken(identifier, password);
      return data;
    } catch (error) {
      const serializedError = miniSerializeError(error);
      return rejectWithValue(serializedError);
    }
  }
);

export const authUserAsyncBuilder = (bulder: ActionReducerMapBuilder<TUserSliceState>) => {
  bulder.addCase(authUserAsync.fulfilled,
    (state, { payload }) => {
      clientCookies.setToken(payload.jwt);
      clientCookies.setUserName(payload.user.username);

      state.status = RequestStatuses.SUCCESS;
      state.error = null;

      state.data.name = payload.user.username;
      state.data.token = payload.jwt;
    }  
  );
}