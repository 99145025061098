import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { apiUrl } from "resources/api";
import { prepareHeadersBase } from "store/baseStateManager";
import { TCreateGroupParams, TGetMoldedSizeGroupsForProductResponse, TUpdateGroupParams } from "./entities";

export const MoldedSizeGroupsApi = createApi({
  reducerPath: 'moldedSizeGroupsApi',
  tagTypes: ['MoldedSizeGroup'],
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl(),
    prepareHeaders: prepareHeadersBase,
  }),
  endpoints: (builder) => ({
    getGroupsForProduct: builder.query<TGetMoldedSizeGroupsForProductResponse, number>({
      query: (productId: number) => ({
        url: 'molded-size-groups',
        params: {
          'filters[product][id]': productId,
          'populate[0]': 'size_units',
          'pagination[pageSize]': 100,
        }
      }),
      providesTags: ['MoldedSizeGroup'],
    }),

    createGroupForProduct: builder.mutation<void, TCreateGroupParams>({
      query: (data: TCreateGroupParams) => ({
        url: 'molded-size-groups',
        method: 'POST',
        body: { data },
      }),
      invalidatesTags: ['MoldedSizeGroup'],
    }),

    updateGroup: builder.mutation<void, TUpdateGroupParams>({
      query: ({ id, price, sale }: TUpdateGroupParams) => ({
        url: `molded-size-groups/${id}`,
        method: 'PUT',
        body: { data: { price, sale } }
      }),
      invalidatesTags: ['MoldedSizeGroup'],
    }),

    removeGroup: builder.mutation<void, number>({
      query: (id: number) => ({
        url: `molded-size-groups/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['MoldedSizeGroup'],
    }),
  }),
});