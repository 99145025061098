import React from 'react';
import { Link } from 'react-router-dom';
import { TSearchedProduct } from 'store/slices/productsApi/entities';
import { baseUrl } from 'resources/api';
import css from './index.module.scss';

type TProps = {
  product: TSearchedProduct,
};

export const SearchedProductCard = ({ product }: TProps) => {
  return (
    <Link className={css.wrap} to={`/${product.product_group.key}/${product.id}`}>
      <p className={css.id}>{ `id: ${product.id}` }</p>
      <div className={css.img} style={{ backgroundImage: `url(${ baseUrl() + product.images[0].url })` }} />
      <div className={css.content}>
        <h3 className={css.title}>{ product.name }</h3>
      </div>
    </Link>
  );
};
