import React from 'react';
import css from './index.module.scss';
import { TProductGroup } from 'store/slices/productGroupsApi/entities';
import { baseUrl } from 'resources/api';
import { measureUnitProductTypeRations } from 'resources/helpers';

type TProps = {
  productGroup: TProductGroup,
};

export const ProductGroupInfo = ({ productGroup }: TProps) => {
  const { description, key, type, saleLimit, amountProducts, image } = productGroup.attributes;

  return (
    <div className={css.wrap}>
      <div className={css.content}>
        <div className={css.field}>
          <p className={css.name}>{ 'Описание' }</p>
          <p className={css.value}>{ description }</p>
        </div>
        <div className={css.field}>
          <p className={css.name}>{ 'Ключ' }</p>
          <p className={css.value}>{ key }</p>
          <p className={css.name}>{ 'Тип товара' }</p>
          <p className={css.value}>{ type }</p>
        </div>
        <div className={css.field}>
          <p className={css.name}>{ 'Скидка от' }</p>
          <p className={css.value}>{ `${saleLimit} ${measureUnitProductTypeRations[type]}` }</p>
          <p className={css.name}>{ 'Товаров' }</p>
          <p className={css.value}>{ amountProducts }</p>
        </div>
      </div>
      <div 
        className={css.img} 
        style={{ backgroundImage: `url(${ baseUrl() + image.data.attributes.url })` }} 
      />
    </div>
  );
};
