import React from 'react';
import css from './index.module.scss';
import { TGalleryImageData, TMadeByTypes } from 'store/slices/galleryApi/entities';

type TProps = TGalleryImageData;

const madeByValues: { [key in TMadeByTypes]: string } = {
  "sample": 'Выставочный образец',
  "our-work": 'Наша работа',
  "customer-photo": 'Фото покупателя',
}

export const GalleryImageData = ({ date, madeBy }: TProps) => {

  return (
    <div className={css.wrap}>
      <h2>Данные</h2>
      <p>Дата: <span>{ date }</span></p>
      <p>Источник: <span>{ madeByValues[madeBy] }</span></p>
    </div>
  );
};
