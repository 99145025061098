import React from 'react';
import { SectionDivider } from 'components';
import { ProductGroupsApi } from 'store/slices';
import css from './index.module.scss';
import { ProductGroupCard } from 'components/ProductGroupCard';

type TProps = {};

export const HomePage = ({  }: TProps) => {
  const { data } = ProductGroupsApi.useGetSectionsQuery();

  return (
    <>
      <h1>Разделы каталога</h1>
      {
        !!data && data.data.map(
          (section, index) => (
            <div key={section.id}>
              <SectionDivider
                number={index + 1}
                title={section.attributes.name} 
                subtitle={section.attributes.description} 
              />
              <div className={css.group}>
                {
                  section.attributes.product_groups.data.map(
                    productGroup => <ProductGroupCard key={productGroup.id} productGroup={productGroup} />
                  )
                }
              </div>
            </div>
          )
        )
      }
    </>
  );
};
