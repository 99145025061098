import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiUrl } from 'resources/api/index';
import { prepareHeadersBase } from 'store/baseStateManager';
import { TGetProductGroupWithKeyResponse, TGetSectionsResponse, TProductGroup } from './entities';


export const ProductGroupsApi = createApi({
  reducerPath: 'productGroupsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl(),
    prepareHeaders: prepareHeadersBase,
  }),
  endpoints: (builder) => ({
    getSections: builder.query<TGetSectionsResponse, void>({
      query: () => ({
        url: 'catalog-sections',
        params: {
          'populate[product_groups][fields][0]': 'key',
          'populate[product_groups][fields][1]': 'name',
          'populate[product_groups][fields][2]': 'amountProducts',
          'populate[product_groups][populate][image][fields][0]': 'url',
          'pagination[pageSize]': '100',
        }
      })
    }),

    getProductGroupWithKey: builder.query<TProductGroup | null, string>({
      query: (key: string) => ({
        url: 'product-groups',
        params: {
          'filters[key]': key,
          'populate[image][fields][0]': 'url',
        }
      }),
      transformResponse: (response: TGetProductGroupWithKeyResponse) => 
        response.data[0] ? response.data[0] : null
    }),
  })
});