import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { apiUrl } from "resources/api";
import { prepareHeadersBase } from "store/baseStateManager";
import { TAnnotation, TConnectAnnotationsMarkers, TElementAnnotationsMarkers, TGetAnnotationsParams, TGetAnnotationsResponse } from "./entities";

export const AnnotationsApi = createApi({
  reducerPath: 'annotationsApi',
  tagTypes: ['Annotation'],
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl(),
    prepareHeaders: prepareHeadersBase,
  }),
  endpoints: (builder) => ({
    getAnnotations: builder.query<TGetAnnotationsResponse, TGetAnnotationsParams>({
      query: ({ search, notIds }) => ({
        url: 'annotations',
        params: {
          'paginatiton[pageSize]': 100,
          'filters[text][$containsi]': search,
          ...notIds.reduce((obj: { [arg: string]: number }, num, ind) => (obj[`filters[id][$notIn][${ind}]`] = num, obj), {}),
        }
      }),
      providesTags: ['Annotation']
    }),

    getElementAnnotations: builder.query<TGetAnnotationsResponse, TElementAnnotationsMarkers>({
      query: ({ id, elementType }) => ({
        url: 'annotations',
        params: {
          [`filters[${elementType}]`]: id
        }
      }),
      providesTags: ['Annotation']
    }),

    disconnectAnnotation: builder.mutation<void, TConnectAnnotationsMarkers>({
      query: ({ annotationId, elementId, elementType }) => ({
        url: `annotations/${annotationId}`,
        method: 'PUT',
        body: {
          'data': {
            [elementType]: {
              disconnect: [elementId]
            }
          }
        }
      }),
      invalidatesTags: ['Annotation']
    }),

    connectAnnotation: builder.mutation<TAnnotation, TConnectAnnotationsMarkers>({
      query: ({ annotationId, elementId, elementType }) => ({
        url: `annotations/${annotationId}`,
        method: 'PUT',
        body: {
          'data': {
            [elementType]: {
              connect: [elementId]
            }
          }
        }
      }),
      invalidatesTags: ['Annotation']
    }),
  }),
});