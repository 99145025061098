import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { apiUrl } from "resources/api";
import { prepareHeadersBase } from "store/baseStateManager";
import { 
  TGetOneProductResponse, 
  TGetProductInProductGroupParams, 
  TGetProductsResponse, 
  TSearchProductParams, 
  TSearchProductResponse
} from "./entities";

export const ProductsApi = createApi({
  reducerPath: 'productsApi',
  tagTypes: ['Product'],
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl(),
    prepareHeaders: prepareHeadersBase,
  }),
  endpoints: (builder) => ({
    getProductsInProductGroup: builder.query<TGetProductsResponse, TGetProductInProductGroupParams>({
      query: ({ productGroupId }) => ({
        url: 'products',
        params: {
          'filters[product_group][id]': productGroupId,
          'populate[images][fields][0]': 'url',
          'populate[product_group][fields][0]': 'key',
          'populate[product_group][fields][1]': 'name',
          'populate[product_group][fields][2]': 'type',
          'pagination[pageSize]': 100,
          'sort': 'id',
        }
      }),
      providesTags: ['Product']
    }),

    getProduct: builder.query<TGetOneProductResponse, number>({
      query: (id) => ({
        url: `products/${id}`,
        params: {
          'populate[images][fields][0]': 'url',
          'populate[product_group][fields][0]': 'key',
          'populate[product_group][fields][1]': 'name',
          'populate[product_group][fields][2]': 'type',
        }
      }),
      providesTags: ['Product']
    }),

    searchProducts: builder.query<TSearchProductResponse, TSearchProductParams>({
      query: ({ query, keywords }) => ({
        url: 'products/search',
        params: {
          limit: 50,
          query,
          keywords: JSON.stringify(keywords),
        },
      }),
      providesTags: ['Product'],
    }),
  }),
});