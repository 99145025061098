import React, { useEffect, useState } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { CalendarIcon, GalleryIcon, GoBackIcon, HomeIcon, RefreshIcon, SearchIcon } from 'resources/icons';
import css from './index.module.scss';
import { RefreshApi } from 'store/slices';

type TProps = {};

export const AppOutlet = ({  }: TProps) => {
  const navigate = useNavigate();
  const [isVisibleRefreshScreen, setIsVisibleRefreshScreen] = useState(false);

  const [ updateAmountProducts, { isSuccess: isUpdateAmountProductsSucces } ] = RefreshApi.useUpdateAmountProductsMutation();
  const [ updateMinPrices, { isSuccess: isUpdateMinPricesSucces } ] = RefreshApi.useUpdateMinPricesMutation();

  const goBack = () => navigate(-1);
  const goHome = () => navigate('/');
  const goGallery = () => navigate('/gallery');
  const goSearch = () => navigate('/search');
  const goPricesUpdated = () => navigate('/outdated');

  const updateData = () => {
    setIsVisibleRefreshScreen(true);
    updateAmountProducts();
    updateMinPrices();
  }

  useEffect(() => {
    if (isUpdateAmountProductsSucces && isUpdateMinPricesSucces) {
      window.location.reload();
    }
  }, [isUpdateAmountProductsSucces, isUpdateMinPricesSucces]);

  return (
    <div className={css.outlet}>
      <header className={css.header}>
        <div className={css.top}>
          <button onClick={goHome} className={css.btnHome}>
            <HomeIcon />
          </button>
          <button onClick={goGallery} className={css.btnGallery}>
            <GalleryIcon />
          </button>
          <button onClick={goSearch} className={css.btnSearch}>
            <SearchIcon />
          </button>
          <button onClick={goPricesUpdated} className={css.btnPricesUpdated}>
            <CalendarIcon />
          </button>
          <button onClick={goBack} className={css.btnGoBack}>
            <GoBackIcon />
          </button>
        </div>
        <div className={css.bottom}>
          <button onClick={updateData} className={css.btnUpdate}>
            <RefreshIcon />
          </button>
        </div>
      </header>

      {
        isVisibleRefreshScreen && (
          <div className={css.refreshScreen}>
            <RefreshIcon className={css.icon} />
            <p className={css.text}>
              Происходит обновление данных, по окончании страница автоматически перезагрузится
            </p>
          </div>
        )
      }

      <div className={css.wrap}>
        <Outlet />
      </div>
    </div>
  );
};
