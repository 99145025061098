import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { apiUrl } from "resources/api";
import { prepareHeadersBase } from "store/baseStateManager";
import { TCreateSizeUnitParams, TGetOutdatedParams, TGetOutdatedResponse, TGetSizeUnitsResponse, TUpdateSizeUnitParams } from "./entities";

export const SizeUnitsApi = createApi({
  reducerPath: 'sizeUnitsApi',
  tagTypes: ['SizeUnit'],
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl(),
    prepareHeaders: prepareHeadersBase,
  }),
  endpoints: (builder) => ({
    getSizeUnitsByProduct: builder.query<TGetSizeUnitsResponse, number>({
      query: (productId: number) => ({
        url: 'size-units',
        params: {
          'filters[product][id]': productId,
          'pagination[pageSize]': 100,
        }
      }),
      providesTags: ['SizeUnit']
    }),

    createSizeUnit: builder.mutation<void, TCreateSizeUnitParams>({
      query: ({ productId, price, sale, isContract }: TCreateSizeUnitParams) => ({
        url: 'size-units',
        method: 'POST',
        body: {
          'data': {
            'product': productId,
            sale, price, isContract,
          }
        }
      }),
      invalidatesTags: ['SizeUnit']
    }),

    updateSizeUnit: builder.mutation<void, TUpdateSizeUnitParams>({
      query: ({ id, data }: TUpdateSizeUnitParams) => ({
        url: `size-units/${id}`,
        method: 'PUT',
        body: { data }
      }),
      invalidatesTags: ['SizeUnit']
    }),

    removeSizeUnit: builder.mutation<void, number>({
      query: (id: number) => ({
        url: `size-units/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SizeUnit']
    }),

    getOutdatedSizeUnit: builder.query<TGetOutdatedResponse, TGetOutdatedParams>({
      query: ({ dateISO, page }: TGetOutdatedParams) => ({
        url: 'size-units',
        method: 'GET',
        params: {
          'populate[product][populate][0]': 'images',
          'filters[moldedLength][$null]': true,
          'sort': 'updatedAt:asc',
          'pagination[pageSize]': 20,
          'pagination[page]': page,
          'filters[updatedAt][$lt]': dateISO,
        },
      }),
      providesTags: ['SizeUnit']
    }),
  }),
});