import React from 'react';

type TProps = {
  className?: string
}

export const GalleryIcon = ({ className = '' }: TProps) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.499 11C8.32743 11 8.999 10.3284 8.999 9.5C8.999 8.67157 8.32743 8 7.499 8C6.67057 8 5.999 8.67157 5.999 9.5C5.999 10.3284 6.67057 11 7.499 11Z" fill="black"/>
    <path d="M10.499 14L8.999 12L5.999 16H17.999L13.499 10L10.499 14Z" fill="black"/>
    <path d="M19.999 4H3.999C2.896 4 1.999 4.897 1.999 6V18C1.999 19.103 2.896 20 3.999 20H19.999C21.102 20 21.999 19.103 21.999 18V6C21.999 4.897 21.102 4 19.999 4ZM3.999 18V6H19.999L20.001 18H3.999Z" fill="black"/>
  </svg>
);