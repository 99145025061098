import React from 'react';

type TProps = {
  className?: string
}

export const HomeIcon = ({ className = '' }: TProps) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.00003 13.0002H4.00003V20.0002C4.00003 21.1032 4.89703 22.0002 6.00003 22.0002H18C19.103 22.0002 20 21.1032 20 20.0002V13.0002H21C21.1978 13.0002 21.3911 12.9415 21.5555 12.8316C21.7199 12.7217 21.848 12.5656 21.9237 12.3829C21.9994 12.2002 22.0192 11.9991 21.9806 11.8052C21.942 11.6112 21.8468 11.4331 21.707 11.2932L12.707 2.2932C12.6142 2.20026 12.504 2.12652 12.3827 2.07621C12.2614 2.0259 12.1314 2 12 2C11.8687 2 11.7387 2.0259 11.6173 2.07621C11.496 2.12652 11.3858 2.20026 11.293 2.2932L2.29303 11.2932C2.15322 11.4331 2.05802 11.6112 2.01945 11.8052C1.98088 11.9991 2.00068 12.2002 2.07635 12.3829C2.15202 12.5656 2.28016 12.7217 2.44457 12.8316C2.60898 12.9415 2.80228 13.0002 3.00003 13.0002ZM10 20.0002V15.0002H14V20.0002H10ZM12 4.4142L18 10.4142V15.0002L18.001 20.0002H16V15.0002C16 13.8972 15.103 13.0002 14 13.0002H10C8.89703 13.0002 8.00003 13.8972 8.00003 15.0002V20.0002H6.00003V10.4142L12 4.4142Z" fill="black"/>
  </svg>
);