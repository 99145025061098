import React, { useRef, useState } from 'react';
import { TProp, TPropAttributes } from 'store/slices/propsApi/entities';
import { PropsApi } from 'store/slices';
import { ActionButton, Input, ModalWindow } from 'components';
import { GhostIcon, PlusIcon } from 'resources/icons';
import css from './index.module.scss';

type TProps = {
  properties: TProp[],
  ownerType: 'product_groups' | 'products' | 'size_units_groups' |'size_units',
  ownerId: number,
};

export const Props = ({ properties, ownerId, ownerType }: TProps) => {
  const [formValues, setFormValues] = useState<TPropAttributes>({ name: '', value: '', measureUnit: null });
  const [isModalVisible, setIsModalVisible] = useState(false);

  const firstFieldRef = useRef<HTMLInputElement>(null);
  const isFormFilled = !!formValues.name && !!formValues.value;

  const [
    create, { isLoading: isCreateLoading }
  ] = PropsApi.useCreatePropMutation();

  const [
    remove, { isLoading: isRemoveLoading }
  ] = PropsApi.useRemovePropMutation();

  const [
    getProps, { data: freeProps, isLoading: isPropsLoading }
  ] = PropsApi.useLazyGetPropsQuery();

  const setInputValue = (inputName: keyof TPropAttributes) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues(prev => ({ ...prev, [inputName]: e.target.value }));
  }

  const clearFormValues = () => {
    setFormValues({ name: '', value: '', measureUnit: null });
  }

  const createHandler = async () => {
    await create({
      ...formValues,
      elementId: ownerId,
      elementType: ownerType,
    }).unwrap();

    clearFormValues();
    if (firstFieldRef.current) firstFieldRef.current.focus();
  }

  const removeHandler = (id: number) => async () => {
    await remove(id).unwrap();
  }

  const hiddenModal = () => setIsModalVisible(false);
  const showModal = () => {
    setIsModalVisible(true);
    if (firstFieldRef.current) firstFieldRef.current.focus();
  }

  return (
    <div className={css.wrap}>
      <h2>Свойства</h2>
      <ActionButton className={css.findBtn} onClick={showModal}>
        <PlusIcon className={css.findIcon} />
      </ActionButton>
      {
        !properties.length && <div className={css.notItems}><GhostIcon /></div>
      }
      {
        properties.map(prop => (
          <div key={prop.id} className={css.item}>
            <div className={css.content}>
              <p className={css.name}>{ prop.attributes.name }</p>
              <p className={css.value}>{ prop.attributes.value }</p>
              <p className={css.measureUnit}>{ prop.attributes.measureUnit || '' }</p>
            </div>
            <ActionButton
              isLoading={isRemoveLoading}
              onClick={removeHandler(prop.id)}
              className={css.removeBtn} 
              colorType='cancel'
            >
              Удалить
            </ActionButton>
          </div>
        ))
      }

      <ModalWindow isVisible={isModalVisible} hiddenAction={hiddenModal} title='Добавить свойство'>
        <div className={css.form}>
          <Input label='Название' value={formValues.name} onChange={setInputValue('name')} refLink={firstFieldRef} />
          <Input label='Значение' value={formValues.value} onChange={setInputValue('value')} />
          <Input label='Ед.изм.' value={formValues.measureUnit || ''} onChange={setInputValue('measureUnit')} />
        </div>
        <ActionButton
          colorType='success'
          disabled={!isFormFilled}
          onClick={createHandler}
          isLoading={isCreateLoading}
          className={css.submitBtn}
        >
          Добавить
        </ActionButton>
      </ModalWindow>
    </div>
  );
};
