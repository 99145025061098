import React from 'react';
import { TProduct } from 'store/slices/productsApi/entities';
import { baseUrl } from 'resources/api';
import { ActionButton } from 'components/ActionButton';
import { useNavigate } from 'react-router-dom';
import css from './index.module.scss';
import { GhostIcon } from 'resources/icons';

type TProps = {
  product: TProduct
};

export const ProductInfo = ({ product }: TProps) => {
  const navigate = useNavigate();
  const images = product.attributes.images.data;
  const { description, inPackage, minPrice, product_group } = product.attributes;

  const goToGroup = () => navigate(`/${product_group.data.attributes.key}`);

  return (
    <div className={css.wrap}>
      <div className={css.content}>
        <div className={css.field}>
          <p className={css.name}>{ 'Описание' }</p>
          <p className={css.value}>{ description || <GhostIcon className={css.icon} /> }</p>
        </div>
        <div className={css.field}>
          <p className={css.name}>{ 'В пачке' }</p>
          <p className={css.value}>{ `${inPackage} шт` }</p>
          <p className={css.name}>{ 'Группа' }</p>
          <p className={css.value}>
            <ActionButton colorType='success' onClick={goToGroup}>Перейти</ActionButton>
          </p>
        </div>
        <div className={css.field}>
          <p className={css.name}>{ 'Минимальная цена' }</p>
          <p className={css.value}>{ `${minPrice} рублей` }</p>
          <p className={css.name}>{ 'Изображений' }</p>
          <p className={css.value}>{ images.length }</p>
        </div>
      </div>
      <div className={css.images}>
        {
          images.map(img => (
            <div
              key={img.id}
              className={css.img} 
              style={{ backgroundImage: `url(${ baseUrl() + img.attributes.url })` }} 
            />
          ))
        }
      </div>
    </div>
  );
};
